// import store from '../store'
import i18n from './i18n'

/*
  A function that intercepts all requests and adds headers to them.
*/
export default function ({ config: { globalProperties } }) {
  const { $axios } = globalProperties

  $axios.interceptors.request.use(async function (config) {
    const { $store } = globalProperties

    if (config.headers['Accept-Language'] === undefined) {
      config.headers['Accept-Language'] = i18n.global.locale
    }

    if ($store.state.user.token) {
      config.headers.Authorization = `Bearer ${$store.state.user.token}`
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  })

  $axios.interceptors.response.use(undefined, (error) => {
    const { $route, $auth } = globalProperties
    const isIgnorePage = $route.path.indexOf('/auth') === -1

    if (isIgnorePage && error.response && (error.response.status === 401 || error.response.status === 403)) {
      $auth.removeUser({})
    }

    return Promise.reject(error.response.data)
  })
}
