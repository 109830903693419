export default {
  state: {
    visible: false
  },
  mutations: {
    EDIT_VISIBLE_MENU: (state, payload) => {
      state.visible = payload
    }
  }
}
