/* eslint-disable no-unused-expressions */
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://backend.demolix.org/api/v1'
})

const axiosStoreInstance = axios.create({
  baseURL: process.env.VUE_APP_STORE_API || ''
})

export default {
  install: ({ config: { globalProperties } }) => {
    globalProperties.$axios = { ...axiosInstance }
    globalProperties.$axiosStore = { ...axiosStoreInstance }
  }
}
