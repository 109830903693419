import i18n from '../../config/i18n'
import * as Cookies from 'js-cookie'

export default {
  install: async ({ config }) => {
    const { globalProperties } = config
    const { $axios, $router, $redirect } = globalProperties

    await $router.isReady()
    const $route = $router.currentRoute._value

    const getToken = function () {
      const {
        $store
      } = globalProperties

      const token = Cookies.get('access_token')

      if (token) {
        $store.commit('USER_SET_TOKEN', token)
      }
    }

    const setToken = function ({ token, refresh = false }) {
      const {
        $store
      } = globalProperties

      const now = new Date()
      const maxAge = new Date()
      maxAge.setDate(now.getDate() + 3)

      Cookies.set('max_age', maxAge, { expires: 7, secure: process.env.NODE_ENV === 'production' })
      $store.commit('USER_SET_TOKEN', token)

      Cookies.set('access_token', token, { expires: 7, secure: process.env.NODE_ENV === 'production' })
      Cookies.set('is_token', refresh, { expires: 1, secure: process.env.NODE_ENV === 'production' })
    }

    const removeToken = function () {
      const {
        $store
      } = globalProperties

      Cookies.remove('access_token')
      Cookies.remove('is_token')

      $store.commit('USER_REMOVE_USER')
      $store.commit('USER_REMOVE_TOKEN')
    }

    globalProperties.$auth = {
      refreshIntervalToken: null,
      refreshIntervalUser: null,

      /* A function that is called when the user logs in. It sets the token and the user in the store. */
      init: async () => {
        const { $auth, $store } = globalProperties
        const { token, refresh } = $route.query

        const initPath = $route.path
        const isInitPathAuth = initPath.indexOf('/auth/') !== -1

        if (isInitPathAuth) {
          const to = initPath.split('/auth/')[1]
          $redirect.auth({ to, from: window.location.href.split('/auth/')[0], query: $route.query })
        }

        if (token) {
          setToken({ token, refresh })
        }

        getToken()
        const isRefresh = Cookies.get('is_token') || false

        if ($store.state.user.token) {
          if (isRefresh) {
            await $auth.refreshToken()
          } else {
            await $auth.fetchUser()
          }

          if ($store.state.user.data?.language) {
            i18n.locale = $store.state.user.data.language

            // $router.replace({
            //   path: `/${i18n.locale}${route.fullPath.slice(3, route.fullPath.length)}`,
            //   params: Object.assign({}, route.params, { locale: i18n.locale }),
            //   query: route.query
            // })

            const query = $route.query
            delete query.token
            delete query.refresh

            $router.replace({
              name: $route.name,
              params: Object.assign({}, $route.params, { locale: i18n.locale }),
              query: query
            })

            $auth.refreshIntervalUser = setInterval(async () => {
              await $auth.fetchUser()
            }, 200000)
          }
        } else {
          $store.commit('PRELOADER_GLOBAL_EDIT', false)
        }
      },

      /* Refreshing the token. */
      refreshToken: async () => {
        const { $auth } = globalProperties

        const now = new Date()
        const max = new Date(Cookies.get('max_age'))

        if (now > max) {
          try {
            const resp = await $axios.post('auth/refresh')

            if (resp.data.status) {
              setToken(resp.data.access_token)
              $auth.fetchUser()
            }
          } catch (err) {
            $auth.removeUser({})
          }
        } else {
          await $auth.fetchUser()
        }
      },

      /* Setting the user in the store. */
      setUser: (options) => {
        const { $store } = globalProperties

        $store.commit('USER_SET_USER', options)

        if (process.env.NODE_ENV === 'production') {
          window.HelpCrunch('updateUser', {
            email: options.email,
            name: `${options.firstname} ${options.surname || ''}`,
            user_id: `${options.id}`
          })

          document.documentElement.lang = options.language
          window.HelpCrunch('setPhraseList', options.language)
        }
      },

      /* A function that returns the user data from the store. */
      getUser: () => {
        const {
          $store
        } = globalProperties

        return $store.state.user.data
      },

      /* Removing the user and redirecting to the sign in page. */
      removeUser: async ({ noRedirect = false, redirectName = null }) => {
        const { $auth, $store } = globalProperties
        const token = Cookies.get('access_token')
        const to = (redirectName === 'ForgotPassword' && 'forgot-password') || 'sign-in'

        if (token) {
          try {
            await $axios.post('auth/logout')
          } catch (err) {
            throw new Error(err)
          } finally {
            clearInterval($auth.refreshIntervalToken)
            clearInterval($auth.refreshIntervalUser)
            removeToken()

            if ($route.fullPath.indexOf('/auth') === -1 && $route.name !== 'SignIn') {
              $redirect.auth({ to, from: window.location.href, query: $route.query })
            }

            $store.commit('PRELOADER_GLOBAL_EDIT', false)
          }
        } else {
          clearInterval($auth.refreshIntervalToken)
          clearInterval($auth.refreshIntervalUser)
          removeToken()

          if ($route.fullPath.indexOf('/auth') === -1 && $route.name !== 'SignIn') {
            $redirect.auth({ to, from: window.location.href, query: $route.query })
          }
        }
      },

      /* Fetching the user data from the server. */
      fetchUser: async () => {
        const { $auth, $store } = globalProperties

        try {
          const resp = await $axios.get('auth/me')

          if (resp.data.status) {
            if (i18n && resp.data.data.language) {
              i18n.locale = resp.data.data.language
            }

            $auth.setUser(resp.data.data)
            $store.dispatch('fetchBalance')
          }
        } catch (err) {
          $auth.removeUser({})
        } finally {
          $store.commit('PRELOADER_GLOBAL_EDIT', false)
        }
      }
    }

    globalProperties.$auth.init()
  }
}
