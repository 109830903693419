const routes = {
  name: 'Default',
  path: '',
  component: () => import('../../layouts/Default.vue'),
  redirect: { name: 'Main' },
  children: [
    {
      name: 'Main',
      path: '',
      component: () => import('../../pages/Main.vue'),
      meta: { title: 'app.page.main' }
    },
    {
      name: 'Balance',
      path: 'balance',
      component: () => import('../../pages/Balance.vue'),
      meta: { title: 'app.page.balance' }
    },
    {
      name: 'NetworkAndRank',
      path: 'network',
      component: () => import('../../pages/network-and-rank/Layout.vue'),
      redirect: { name: 'Network' },
      children: [
        {
          name: 'Network',
          path: ':page(\\d+)?',
          component: () => import('../../pages/network-and-rank/Network.vue'),
          meta: { title: 'app.page.network' }
        },
        {
          name: 'Rank',
          path: 'rank',
          component: () => import('../../pages/network-and-rank/Rank.vue'),
          meta: { title: 'app.page.network' }
        }
      ]
    },
    {
      name: 'Store',
      path: 'store',
      redirect: { name: 'StoreContainers' },
      children: [
        {
          name: 'StoreContainers',
          path: '',
          component: () => import('../../pages/store/StoreContainers.vue'),
          meta: { title: 'app.page.store' }
        },
        {
          name: 'StoreContainerArchive',
          path: 'archive/:page(\\d+)?',
          component: () => import('../../pages/store/StoreContainerArchive.vue'),
          meta: { title: 'app.page.store' }
        },
        {
          name: 'StoreContainerCheckout',
          path: 'checkout/:id(\\d+)',
          component: () => import('../../pages/store/StoreContainerCheckout.vue')
        }
      ]
    },
    {
      name: 'Transactions',
      path: 'transactions',
      component: () => import('../../pages/transactions/Layout.vue'),
      redirect: { name: 'TransactionsPurchase' },
      children: [
        {
          name: 'TransactionsPurchase',
          path: 'my-purchases/:page(\\d+)?',
          component: () => import('../../pages/transactions/TransactionsPurchase.vue'),
          meta: { title: 'app.page.purchases' }
        },
        {
          name: 'TransactionsOrder',
          path: 'my-orders/:page(\\d+)?',
          component: () => import('../../pages/transactions/TransactionsOrder.vue'),
          meta: { title: 'app.page.purchases' }
        }
      ]
    },
    {
      name: 'Profile',
      path: 'profile',
      redirect: { name: 'ProfileIndex' },
      children: [
        {
          name: 'ProfileIndex',
          path: '',
          component: () => import('../../pages/profile/Index.vue')
        },
        {
          name: 'AdvertisingСampaigns',
          path: 'advertising-campaigns',
          component: () => import('../../pages/profile/advertising-campaigns/Index.vue')
        },
        {
          name: 'AdvertisingСampaignsSelect',
          path: 'advertising-campaigns/:id',
          component: () => import('../../pages/profile/advertising-campaigns/Select.vue')
        }
      ]
    },
    {
      name: 'Payouts',
      path: 'transactions/payouts/:page(\\d+)?',
      component: () => import('../../pages/Payouts.vue')
    },
    {
      name: 'News',
      path: 'news',
      redirect: { name: 'NewsList' },
      children: [
        {
          name: 'NewsList',
          path: ':page(\\d+)?',
          component: () => import('../../pages/news/NewsList.vue')
        },
        {
          name: 'NewsSelect',
          path: ':slug',
          component: () => import('../../pages/news/NewsSelect.vue')
        }
      ]
    },
    {
      name: 'Support',
      path: 'support',
      component: () => import('../../pages/Support.vue')
    },
    {
      name: 'Forms',
      path: 'forms/:slug',
      component: () => import('../../pages/Forms.vue')
    },
    {
      name: 'PublicOffer',
      path: 'public-offer',
      component: () => import('../../pages/PublicOffer.vue')
    },
    {
      name: 'PrivacyPolicy',
      path: 'privacy-policy',
      component: () => import('../../pages/PrivacyPolicy.vue')
    }
  ]
}

export default routes
