export default {
  state: {
    width: 0,
    to425: false,
    to768: false,
    to1024: false
  },
  mutations: {
    WINDOW_ONRESIZE: (state, payload) => {
      const width = payload.innerWidth
      state.width = width

      if (width <= 425) {
        state.to425 = true
      } else {
        state.to425 = false
      }

      if (width <= 768) {
        state.to768 = true
      } else {
        state.to768 = false
      }

      if (width <= 1024) {
        state.to1024 = true
      } else {
        state.to1024 = false
      }
    }
  }
}
