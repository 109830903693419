import { globals } from '../main'

export default {
  state: {
    data: null,
    token: null,
    balance: {},
    fingerprint: null
  },
  mutations: {
    /* Setting the state of the user to the payload and removing the user. */
    USER_SET_USER: (state, payload) => {
      state.data = payload
    },
    USER_REMOVE_USER: (state) => {
      state.data = null
    },

    /* Setting the token to the payload and removing the token. */
    USER_SET_TOKEN: (state, payload) => {
      state.token = payload
    },
    USER_REMOVE_TOKEN: (state) => {
      state.token = null
    },

    USER_SET_BALANCE: (state, payload) => {
      state.balance = payload
    },
    USER_SET_FINGERPRINT: (state, payload) => {
      state.fingerprint = payload
    }
  },
  actions: {
    async fetchBalance ({ state, commit, dispatch }, axios) {
      try {
        const resp = await globals.$axios.get(
          '/private/widgets/all-pages-balance')

        if (resp.status === 200) {
          commit('USER_SET_BALANCE', resp.data.data)
          dispatch('fetchInfoPopUp')
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchInfoPopUp ({ state, commit }) {
      try {
        const resp = await globals.$axios.get(
          '/private/account-popups')

        if (resp.status === 200 && resp?.data?.data?.length >= 1) {
          commit('POP_SET_UP', {
            name: 'universal',
            data: resp.data.data
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  },
  getters: {
    isAuth (state) {
      return state.data && state.token
    }
  }
}
