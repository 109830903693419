/* eslint-disable func-call-spacing */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line one-var
var a = false,
  b = null

try {
  document.addEventListener(
    'swUpdated', updateAvailable, { once: true })

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (a) return
    a = true

    window.location.reload()
  })
} catch (error) {
  console.log('sw-updated ', error)
}

function updateAvailable (event) {
  b = event.detail

  if (!a || !b.waiting) return
  b && b.waiting.postMessage({ type: 'SKIP_WAITING' })
}
