export default {
  install: (app, options) => {
    const body = document.body
    const { config: { globalProperties } } = app
    const { $store } = globalProperties

    globalProperties.$menu = {
      up: () => {
        $store.commit('EDIT_VISIBLE_MENU', true)
        body.style.overflow = 'hidden'
      },
      down: () => {
        $store.commit('EDIT_VISIBLE_MENU', false)
        body.style.overflow = 'auto'
      }
    }
  }
}
