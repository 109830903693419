import { RouterView } from 'vue-router'
// import Tr from '@/config/i18n/translation.js'

import accountRoute from './account'
import authRoute from './auth'

const routes = [
  {
    path: '/:locale',
    component: RouterView,
    // beforeEnter: Tr.routeMiddleware,
    children: [
      accountRoute,
      authRoute,
      {
        name: 'Payment',
        path: 'payment/:order(\\d+)',
        component: () => import('../../layouts/Payment.vue')
      }
    ]
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('../../layouts/Error.vue')
  }
]

export default routes
