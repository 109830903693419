<template>
  <div class="user-id">
    <!-- <template
      v-if="_getUserTurnover === 0"
    >
      <div
        class="user-id__text"
        :class="{
          opacity: notAvailableInfo
        }"
      >
        ID {{ $t('components.ui.buttons.user-id.not-available') }}
      </div>

      <div
        class="user-id__button not-available"
        :class="{
          active: notAvailableInfo
        }"
        @click="notAvailableInfo ? notAvailableInfo = false : notAvailableInfo = true"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_6678_83450)">
          <path d="M8.43011 5.5713C8.59774 5.57085 8.75837 5.50406 8.8769 5.38553C8.99544 5.267 9.06223 5.10636 9.06268 4.93873V4.30445C9.06268 3.95873 8.77868 3.67188 8.43011 3.67188C8.08154 3.67188 7.79639 3.95873 7.79639 4.30445V4.93873C7.79639 5.28673 8.08154 5.5713 8.43011 5.5713ZM7.16496 8.1033H7.79639V11.6907C7.79639 12.0387 8.08154 12.3233 8.43011 12.3233C8.59774 12.3229 8.75837 12.2561 8.8769 12.1375C8.99544 12.019 9.06223 11.8584 9.06268 11.6907V7.47016C9.06223 7.30253 8.99544 7.1419 8.8769 7.02337C8.75837 6.90483 8.59774 6.83804 8.43011 6.83759H7.16496C6.81582 6.83759 6.53125 7.12273 6.53125 7.47016C6.53125 7.81873 6.81582 8.1033 7.16496 8.1033Z" fill="#202539"/>
          <path v-if="!notAvailableInfo" d="M7.99777 15.8569C3.6652 15.8569 0.140625 12.3323 0.140625 8.00029C0.140625 3.66772 3.6652 0.142578 7.99777 0.142578C12.3303 0.142578 15.8549 3.66772 15.8549 8.00029C15.8549 12.3323 12.3303 15.8569 7.99777 15.8569ZM7.99777 0.714007C3.98062 0.714007 0.712053 3.98258 0.712053 8.00029C0.712053 12.0174 3.98062 15.2854 7.99777 15.2854C12.0149 15.2854 15.2835 12.0174 15.2835 8.00029C15.2835 3.98258 12.0149 0.714007 7.99777 0.714007Z" fill="#202539"/>
          </g>
          <defs>
          <clipPath id="clip0_6678_83450">
          <rect width="16" height="16" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </div>

      <div
        v-if="notAvailableInfo"
        class="user-id__info"
        v-click-outside="hide"
      >
        <div
          class="user-id__info__text"
          v-html="$t('components.ui.buttons.user-id.text')"
        />

        <LightButton
          class="h46"
          :text="$t('components.ui.buttons.user-id.button')"
          @click="$router.push({ name: 'Store' }), hide()"
        />
      </div>
    </template> -->

    <div class="user-id__text">
      ID #{{ _getUserId }}
    </div>

    <div
      class="user-id__button"
      :class="{
        copy : isCopy
      }"
      @click="copy()"
    >
      <svg
        v-if="!isCopy"
        width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6597_89085)">
        <path d="M12.0166 13.5557H5.04395C4.19576 13.5557 3.50586 12.8658 3.50586 12.0176V5.04492C3.50586 4.19673 4.19576 3.50684 5.04395 3.50684H12.0166C12.8648 3.50684 13.5547 4.19673 13.5547 5.04492V12.0176C13.5547 12.8658 12.8648 13.5557 12.0166 13.5557ZM5.04395 4.53223C4.76124 4.53223 4.53125 4.76222 4.53125 5.04492V12.0176C4.53125 12.3003 4.76124 12.5303 5.04395 12.5303H12.0166C12.2993 12.5303 12.5293 12.3003 12.5293 12.0176V5.04492C12.5293 4.76222 12.2993 4.53223 12.0166 4.53223H5.04395ZM2.48047 9.4541H1.96777C1.68507 9.4541 1.45508 9.22411 1.45508 8.94141V1.96875C1.45508 1.68605 1.68507 1.45605 1.96777 1.45605H8.94043C9.22313 1.45605 9.45313 1.68605 9.45313 1.96875V2.45581H10.4785V1.96875C10.4785 1.12056 9.78862 0.430664 8.94043 0.430664H1.96777C1.11958 0.430664 0.429688 1.12056 0.429688 1.96875V8.94141C0.429688 9.7896 1.11958 10.4795 1.96777 10.4795H2.48047V9.4541Z" fill="#202539"/>
        </g>
        <defs>
        <clipPath id="clip0_6597_89085">
        <rect width="14" height="14" fill="white"/>
        </clipPath>
        </defs>
      </svg>

      <svg
        v-else
        width="20"
        height="15"
        style="min-width: 14px; min-height: 15px;"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 6.28571L7.81818 12L18 2" stroke="#D3FF53" stroke-width="3" stroke-linecap="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

// import LightButton from '@/ui/buttons/LightButton.vue'

export default {
  components: {
    // LightButton
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      isCopy: false,
      notAvailableInfo: false
    }
  },
  computed: {
    _getUserTurnover () {
      return this.$store.state.user.data?.turnover_user || 0
    },
    _getUserId () {
      return this.$store.state.user.data?.id
    }
  },
  methods: {
    copy () {
      this.isCopy = true
      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = this._getUserId

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.isCopy = false
      }, 4000)
    },
    hide () {
      this.notAvailableInfo = false
    }
  }
}
</script>

<style lang="scss" scoped>
.user-id {
  position: relative;
  height: 36px;
  padding: 5px 5px 5px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(47, 87, 233, .2);
  // backdrop-filter: blur(30px);
  border-radius: 30px;
  z-index: 100;

  .user-id__text {
    font-size: 14px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  .user-id__button {
    height: 26px;
    width: 26px;
    padding: 4px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 50%;
    transition: .2s;
    cursor: pointer;

    svg {
      width: 100%;
      max-width: 14px;
      margin: 0 auto;
    }

    &.copy {
      background: rgb(var(--color-dark));
    }

    &.not-available {
      position: relative;
      padding: 0;
      border: 5px solid transparent;

      svg {
        height: 14px;
        width: 14px;
        min-width: 14px;
      }

      &.active {
        border: 5px solid #577FFF;

        &::after {
          content: '';
        }

        svg {
          path {
            fill: #577FFF;
          }
        }
      }

      &::after {
        position: absolute;
        top: 6px;
        left: calc(100% - 18px);
        height: 36px;
        width: 16px;
        border-bottom: 16px solid #FFFFFF;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        z-index: 2;
      }
    }
  }

  .user-id__info {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100vw - (10px * 2));
    max-width: 340px;
    margin-top: 15px;
    padding: 20px 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #FFFFFF;
    border-radius: 15px;
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, .15));
  }
}

@media screen and (max-width: 1024px) {
  .user-id {
    padding: 6px 6px 6px 12px;
    background: rgba(156, 163, 184, .6);
    backdrop-filter: none;

    .user-id__text {
      color: #FFFFFF;

      &.opacity {
        color: rgba(255, 255, 255, .6);
      }
    }
    .user-id__info {
      max-width: 390px;
    }
  }
}
</style>

<style lang="scss">
.user-id__info__text {
  font-size: 14px;
  line-height: 140%;
  color: #202539;
  font-family: Medium;

  span {
    &.blue-txt {
      color: #2F57E9;
      font-family: inherit;
    }

    &.green-txt {
      color: #00B207;
      font-family: SemiBold;
    }
  }
}
</style>
