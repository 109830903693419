export default {
  install: (app, options) => {
    const { config: { globalProperties } } = app
    const { $store } = globalProperties

    globalProperties.$pop = {
      up: (options) => {
        $store.commit('POP_SET_UP', options)
      },
      down: () => {
        $store.commit('POP_SET_DOWN')
      }
    }
  }
}
