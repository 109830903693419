/* eslint-disable no-unused-expressions */
/* eslint-disable import/first */
import { createApp } from 'vue'
import App from './App.vue'

if (process.env.NODE_ENV === 'production') {
  import('./plugins/metrics/gtm')
}

// Import default
import store from './store'
import router from './router'

// Import configs
import i18n from './config/i18n'
import requests from './config/requests'
import './config/pwa/registerServiceWorker'

// Import global function
import auth from './plugins/globals/auth'
import axios from './plugins/globals/axios'
import popUp from './plugins/globals/pop-up'
import format from './plugins/globals/format'
import window from './plugins/window'
import menu from './plugins/globals/menu'
import gtm from './plugins/globals/gtm'
import redirect from './plugins/globals/redirect'

import './plugins/help-crunch'
import './plugins/metrics/fingerprint'

// Import sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

// Init App
const app = createApp(App)

// Use plugins
app
  .use(redirect)
  .use(store)
  .use(i18n)
  .use(router)
  .use(axios)
  .use(requests)

  .use(gtm)
  .use(popUp)
  .use(format)
  .use(window)
  .use(menu)

  // Init auth
  .use(auth)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://8bc63dbfd1184100afd3fc7993a3dbde@o4503963441299456.ingest.sentry.io/4503963447787520',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['onlix.fun', /^\//]
      })
    ],
    tracesSampleRate: 1.0
  })
}

// Start app
app.mount('#app')

// app.config.globalProperties.$auth.init()

// Export global properties
const globals = app.config.globalProperties
export { globals }
