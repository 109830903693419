export default {
  state: {
    list: []
  },
  mutations: {
    WITHDRAWAL_SET_LIST: (state, payload) => {
      state.list = payload
    },
    WITHDRAWAL_ADD_ITEM: (state, payload) => {
      state.list.push(payload)
    },
    WITHDRAWAL_REMOVE_ITEM: (state, payload) => {
      for (const i in state.list) {
        if (state.list[i].id === payload) {
          state.list.splice(i, 1)
        }
      }
    }
  }
}
