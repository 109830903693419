export default {
  state: {
    data: [
      {
        name: 'tg',
        link: 'https://t.me/OnliX_official'
      },
      {
        name: 'yt',
        link: 'https://www.youtube.com/channel/UCWjWtU68mAoQngHw9iztRjQ'
      },
      {
        name: 'inst',
        link: 'https://www.instagram.com/onlix.official/'
      },
      {
        name: 'fb',
        link: 'https://www.facebook.com/onlix.official'
      }
      // {
      //   name: 'tt',
      //   link: 'https://www.google.com/'
      // }
    ]
  }
}
