export default {
  install: ({ config: { globalProperties } }) => {
    globalProperties.$format = {
      wallet: (value = 0, symbol = null) => {
        const dot = String(value).split('.')[1]
        const clear = String(value).split('.')[0]
        return `${String(clear).replace(/\B(?=(\d{3})+(?!\d))/g, symbol || ',')}${dot?.length && Number(dot) > 0 ? `.${dot.slice(0, 2)}` : ''}`
      },
      date: (date) => {
        let d

        if (date) {
          d = new Date(date)
        } else {
          d = new Date()
        }

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

        return `${da}.${mo}.${ye}`
      },
      dateTime: (date) => {
        let d

        if (date) {
          d = new Date(date)
        } else {
          d = new Date()
        }

        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
        const time = new Intl.DateTimeFormat('ru', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        }).format(d)

        return `${da}.${mo}.${ye} / ${time}`
      }
    }
  }
}
