<template>
  <div
    class="view"
    id="view"
  >
    <RouterView v-if="!_error" />

    <Error v-else />
  </div>

  <Transition>
    <PopUp v-if="$store.state.popUp.active" />
  </Transition>

  <Transition>
    <Preloader v-if="$store.state.preloader.global" />
  </Transition>
</template>

<script>
import Error from '@/layouts/Error.vue'
import PopUp from '@/modules/app/pop-up/Container.vue'
import Preloader from '@/ui/preloader/GlobalAnimatePreloader.vue'

export default {
  components: { Error, PopUp, Preloader },
  computed: {
    _error () {
      return !!this.$store.state.error.data
    }
  },
  mounted () {
    this.setUserLocale()
  },
  methods: {
    setUserLocale () {
      const user = this.$store.state.user.data

      if (user?.language) {
        this.$i18n.locale = user.language
      }
    }
  },
  watch: {
    '$store.state.preloader.global' () {
      const preloader = this.$store.state.preloader.global
      // const token = !!Cookies.get('is_token')

      if (!preloader && !this.$store.getters.isAuth) {
        this.$auth.removeUser({})
      }
    },
    '$store.state.user.data' () {
      this.setUserLocale()
    },
    '$route' () {
      if (this.$route.params?.locale !== this.$root.$i18n.locale) {
        setTimeout(() => {
          this.$root.$i18n.locale = this.$route.params.locale
          document.documentElement.lang = this.$route?.params?.locale || 'en'
        }, 100)
      }
    },
    '$route.path' () {
      const elem = document.querySelector('#view')
      elem.scrollTo(0, 0)
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/main';

.view {
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    max-height: none !important;
  }
}

@media screen and (max-width: 768px) {
  body {
    min-height: auto !important;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
