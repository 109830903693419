import { createStore } from 'vuex'

import preloader from './preloader'
import user from './user'
import popUp from './popUp'
import withdrawal from './withdrawal'
import window from './window'
import menu from './menu'
import social from './social'
import error from './error'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    preloader,
    user,
    popUp,
    withdrawal,
    window,
    menu,
    social,
    error
  }
})
