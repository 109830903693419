<template>
  <header
    class="header"
    :class="{
      auth: simple
    }"
  >
    <div class="header__left">
      <div
        v-if="simple"
        class="header__left__logo"
      >
        <RouterLink
          v-if="$route.path.indexOf('/payment/') !== -1"
          :to="{ name: 'Main' }"
        >
          <img src="@/static/svg/authorization-logo.svg">
        </RouterLink>

        <a
          v-else
          :href="_getSiteLink"
        >
          <img src="@/static/svg/authorization-logo.svg">
        </a>
      </div>

      <Locale />
    </div>

    <div
      v-if="!simple"
      class="header__info"
    >
      <UserId />

      <InviteButton
        style="margin-left: 10px;"
        class="nouppercase"
        @click="$router.push({ name: 'Main' })"
      />

      <div class="header__info__profile__container">
        <button
          class="header__info__profile"
          :class="{
            active : isProfile
          }"
          @click.stop="isProfile ? isProfile = false : isProfile = true"
        >
          <div class="header__info__profile__text button-text">
            {{ $t('components.app.header.profile') }}
          </div>

          <div class="header__info__profile__icon button-icon">
            <svg
              width="18"
              height="18"
              style="min-height: 18px; min-width: 18px;"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 6.6C12.2 8.36731 10.7673 9.8 9 9.8C7.23269 9.8 5.8 8.36731 5.8 6.6C5.8 4.83269 7.23269 3.4 9 3.4C10.7673 3.4 12.2 4.83269 12.2 6.6ZM11.4 6.6C11.4 7.92548 10.3255 9 9 9C7.67452 9 6.6 7.92548 6.6 6.6C6.6 5.27452 7.67452 4.2 9 4.2C10.3255 4.2 11.4 5.27452 11.4 6.6Z" fill="white" class="fill" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 9C17 10.801 16.4049 12.4629 15.4006 13.8C15.327 13.898 15.2511 13.9943 15.1732 14.0887C13.7059 15.8667 11.4853 17 9 17C6.3827 17 4.05896 15.7431 2.59943 13.8C1.59512 12.463 1 10.801 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM9 16.2L9.01284 16.2C11.1401 16.1963 13.051 15.27 14.3667 13.8C13.0474 12.3259 11.1319 11.4 9 11.4C6.86809 11.4 4.95263 12.3259 3.63332 13.8C4.94905 15.27 6.8599 16.1963 8.98716 16.2L9 16.2ZM9 10.6C11.3209 10.6 13.411 11.5883 14.8723 13.1671C15.7084 11.991 16.2 10.5529 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 10.5529 2.29162 11.991 3.12771 13.1671C4.58903 11.5883 6.6791 10.6 9 10.6Z" fill="white" class="fill" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 6.6C12.2 8.36731 10.7673 9.8 9 9.8C7.23269 9.8 5.8 8.36731 5.8 6.6C5.8 4.83269 7.23269 3.4 9 3.4C10.7673 3.4 12.2 4.83269 12.2 6.6ZM11.4 6.6C11.4 7.92548 10.3255 9 9 9C7.67452 9 6.6 7.92548 6.6 6.6C6.6 5.27452 7.67452 4.2 9 4.2C10.3255 4.2 11.4 5.27452 11.4 6.6Z" stroke="white" stroke-width="0.5" class="stroke" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17 9C17 10.801 16.4049 12.4629 15.4006 13.8C15.327 13.898 15.2511 13.9943 15.1732 14.0887C13.7059 15.8667 11.4853 17 9 17C6.3827 17 4.05896 15.7431 2.59943 13.8C1.59512 12.463 1 10.801 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM9 16.2L9.01284 16.2C11.1401 16.1963 13.051 15.27 14.3667 13.8C13.0474 12.3259 11.1319 11.4 9 11.4C6.86809 11.4 4.95263 12.3259 3.63332 13.8C4.94905 15.27 6.8599 16.1963 8.98716 16.2L9 16.2ZM9 10.6C11.3209 10.6 13.411 11.5883 14.8723 13.1671C15.7084 11.991 16.2 10.5529 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 10.5529 2.29162 11.991 3.12771 13.1671C4.58903 11.5883 6.6791 10.6 9 10.6Z" stroke="white" stroke-width="0.5" class="stroke" />
            </svg>
          </div>
        </button>

        <div
          v-if="isProfile"
          class="header__info__profile__container__drop"
          v-click-outside="hideProfile"
        >
          <button
            class="profile"
            @click="$router.push({ name: 'Profile' })"
          >
            {{ $t('components.app.header.profile') }}

            <div class="profile__icon button-icon">
              <svg
                width="18"
                height="18"
                style="min-height: 18px; min-width: 18px;"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 6.6C12.2 8.36731 10.7673 9.8 9 9.8C7.23269 9.8 5.8 8.36731 5.8 6.6C5.8 4.83269 7.23269 3.4 9 3.4C10.7673 3.4 12.2 4.83269 12.2 6.6ZM11.4 6.6C11.4 7.92548 10.3255 9 9 9C7.67452 9 6.6 7.92548 6.6 6.6C6.6 5.27452 7.67452 4.2 9 4.2C10.3255 4.2 11.4 5.27452 11.4 6.6Z" fill="#787C8D" class="fill" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 9C17 10.801 16.4049 12.4629 15.4006 13.8C15.327 13.898 15.2511 13.9943 15.1732 14.0887C13.7059 15.8667 11.4853 17 9 17C6.3827 17 4.05896 15.7431 2.59943 13.8C1.59512 12.463 1 10.801 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM9 16.2L9.01284 16.2C11.1401 16.1963 13.051 15.27 14.3667 13.8C13.0474 12.3259 11.1319 11.4 9 11.4C6.86809 11.4 4.95263 12.3259 3.63332 13.8C4.94905 15.27 6.8599 16.1963 8.98716 16.2L9 16.2ZM9 10.6C11.3209 10.6 13.411 11.5883 14.8723 13.1671C15.7084 11.991 16.2 10.5529 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 10.5529 2.29162 11.991 3.12771 13.1671C4.58903 11.5883 6.6791 10.6 9 10.6Z" fill="#787C8D" class="fill" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2 6.6C12.2 8.36731 10.7673 9.8 9 9.8C7.23269 9.8 5.8 8.36731 5.8 6.6C5.8 4.83269 7.23269 3.4 9 3.4C10.7673 3.4 12.2 4.83269 12.2 6.6ZM11.4 6.6C11.4 7.92548 10.3255 9 9 9C7.67452 9 6.6 7.92548 6.6 6.6C6.6 5.27452 7.67452 4.2 9 4.2C10.3255 4.2 11.4 5.27452 11.4 6.6Z" stroke="#787C8D" stroke-width="0.5" class="stroke" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 9C17 10.801 16.4049 12.4629 15.4006 13.8C15.327 13.898 15.2511 13.9943 15.1732 14.0887C13.7059 15.8667 11.4853 17 9 17C6.3827 17 4.05896 15.7431 2.59943 13.8C1.59512 12.463 1 10.801 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9ZM9 16.2L9.01284 16.2C11.1401 16.1963 13.051 15.27 14.3667 13.8C13.0474 12.3259 11.1319 11.4 9 11.4C6.86809 11.4 4.95263 12.3259 3.63332 13.8C4.94905 15.27 6.8599 16.1963 8.98716 16.2L9 16.2ZM9 10.6C11.3209 10.6 13.411 11.5883 14.8723 13.1671C15.7084 11.991 16.2 10.5529 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 10.5529 2.29162 11.991 3.12771 13.1671C4.58903 11.5883 6.6791 10.6 9 10.6Z" stroke="#787C8D" stroke-width="0.5" class="stroke" />
              </svg>
            </div>
          </button>

          <button
            class="logout"
            @click="$pop.up('signOut')"
          >
            {{ $t('components.app.header.logout') }}

            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.97672 12.814H1.7441C1.42316 12.814 1.16271 12.5535 1.16271 12.2326V1.76741C1.16271 1.44647 1.42319 1.18602 1.7441 1.18602H6.97672C7.29823 1.18602 7.55811 0.926146 7.55811 0.604636C7.55811 0.283125 7.29823 0.0231934 6.97672 0.0231934H1.7441C0.782466 0.0231934 -9.15527e-05 0.805778 -9.15527e-05 1.76741V12.2326C-9.15527e-05 13.1942 0.782466 13.9768 1.7441 13.9768H6.97672C7.29823 13.9768 7.55811 13.7169 7.55811 13.3954C7.55811 13.0739 7.29823 12.814 6.97672 12.814Z" fill="#787C8D"/>
              <path d="M13.8267 6.586L10.2918 3.09759C10.0639 2.872 9.69529 2.87493 9.4697 3.10341C9.24411 3.3319 9.24644 3.69992 9.47552 3.92551L12.0017 6.41854H5.23245C4.91094 6.41854 4.65106 6.67842 4.65106 6.99993C4.65106 7.32144 4.91094 7.58134 5.23245 7.58134H12.0017L9.47552 10.0744C9.24646 10.3 9.24471 10.668 9.4697 10.8965C9.58364 11.0116 9.73365 11.0697 9.88366 11.0697C10.0313 11.0697 10.179 11.0139 10.2918 10.9023L13.8267 7.41386C13.9372 7.30457 14 7.15571 14 6.9999C14 6.84415 13.9378 6.69589 13.8267 6.586Z" fill="#787C8D"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import UserId from '@/ui/buttons/UserId.vue'
import Locale from '@/ui/buttons/Locale.vue'
import InviteButton from '@/ui/buttons/InviteButton.vue'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    UserId,
    Locale,
    InviteButton
  },
  props: {
    simple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isProfile: false
    }
  },
  computed: {
    _getSiteLink () {
      return `https://demolix.org${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}`}`
    }
  },
  methods: {
    hideProfile () {
      setTimeout(() => {
        this.isProfile = false
      }, 250)
    }
  },
  watch: {
    '$route' () {
      this.isProfile = false
    }
  }
}
</script>

<style lang="scss" scoped>
header.header {
  position: relative;
  height: 80px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid  rgba(var(--border-line-color));
  z-index: 10;

  .header__left {
    display: flex;
    align-items: center;

    .header__left__logo {
      height: 60px;
      margin-right: 30px;
      padding-right: 140px;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(var(--border-line-color));
    }
  }

  .header__info {
    display: flex;
    align-items: center;

    .header__info__profile__container {
      position: relative;
      margin-left: 10px;

      .header__info__profile__container__drop {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin: 4px 0 0;
        padding: 2px;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, .15);
        border-radius: 17px;

        button {
          width: 100%;
          margin: 0;
          padding: 0 8px;
          display: flex;
          justify-content: space-between;
          border-radius: 20px;
          font-size: 14px;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;
          transition: .2s;

          svg path {
            transition: .2s;
          }

          &.profile {
            padding: 0 4px 0 8px;

            .profile__icon {
              height: 28px;
              width: 28px;
              border-radius: 50%;
            }

            &:hover {
              background: rgba(var(--color-light-blue), 1);
              color: white;

              .profile__icon {
                background: white;

                svg path {
                  transition: .2s;

                  &.fill {
                    fill: rgba(var(--primary-color));
                  }

                  &.stroke {
                    stroke: rgba(var(--primary-color));
                  }
                }
              }
            }

            &:active {
              background: rgba(var(--color-dark));
              color: white;

              .profile__icon {
                background: white;

                svg path {
                  transition: .2s;

                  &.fill {
                    fill: rgba(var(--primary-color));
                  }

                  &.stroke {
                    stroke: rgba(var(--primary-color));
                  }
                }
              }
            }
          }

          &.logout:hover {
            background: rgba(218, 40, 40, .1);
            color: #DA2828;

            svg path {
              fill: #DA2828;
            }
          }
        }
      }
    }

    button {
      height: 36px;
      margin-left: 10px;
      padding: 4px;
      display: flex;
      align-items: center;
      border-radius: 30px;
      border: none;
      transition: .2s;
      cursor: pointer;

      .button-text {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-family: SemiBold;
        line-height: 80%;
        transition: .2s;
      }

      .button-icon {
        height: 28px;
        width: 28px;
        min-width: 28px;
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: .2s;
      }

      &.header__info__profile {
        min-width: 120px;
        margin: 0;
        background: rgba(255, 255, 255, 1);

        .button-text {
          color: rgba(var(--color-dark));
        }

        .button-icon {
          background: rgba(var(--color-dark));
        }

        &.active {
          background: rgba(var(--color-dark));

          .button-text {
            color: white;
          }

          .button-icon {
            background: white;

            svg path {
              transition: .2s;

              &.fill {
                fill: rgba(var(--primary-color));
              }

              &.stroke {
                stroke: rgba(var(--primary-color));
              }
            }
          }
        }

        &:hover {
          background: rgba(var(--color-light-blue), 1);

          .button-text {
            color: white;
          }

          .button-icon {
            background: white;

            svg path {
              transition: .2s;

              &.fill {
                fill: rgba(var(--primary-color));
              }

              &.stroke {
                stroke: rgba(var(--primary-color));
              }
            }
          }
        }

        &:active {
          background: rgba(var(--color-dark));

          .button-text {
            color: white;
          }

          .button-icon {
            background: white;

            svg path {
              transition: .2s;

              &.fill {
                fill: rgba(var(--primary-color));
              }

              &.stroke {
                stroke: rgba(var(--primary-color));
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header.header {
    height: 60px;
    width: 100%;
    padding: 0 10px;

    &.auth {
      .header__left {
        display: flex;
      }
    }

    .header__left {
      width: 100%;
      display: none;

      .header__left__logo {
        height: 50px;
        width: 100%;
        padding-right: 0;
      }
    }

    .header__info {
      display: none;
    }
  }
}
</style>
