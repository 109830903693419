<template>
  <component
    :is="tag"
    class="button-text"
    @click.stop="$emit('onclick')"
  >
    <template v-if="!loading">
      {{ text }}
    </template>

    <div
      v-else
      class="button-text__loader"
    />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    text: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
button, a {
  position: relative;
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--primary-color));
  border-radius: 30px;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-family: SemiBold;
  font-size: 14px;
  transition: .2s;

  &.light {
    background: rgba(var(--primary-color), .2);
    color: rgba(var(--color-dark));

    &:hover {
      background: rgb(var(--color-light-blue));
      color: white;
    }

    &:active {
      background: rgba(var(--color-dark));
      color: white;
    }

    &:disabled {
      background: rgba(var(--primary-color), .1);
      color: rgba(var(--text-color-light));
      cursor: not-allowed;
    }
  }

  &.yellow {
    background: #D3FF53;
    color: #202539;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #D3FF53;
      color: #202539;
    }

    &:active {
      background: #202539;
      color: rgba(255, 255, 255, 0.85);
    }
  }

  &:hover {
    background: rgb(var(--color-light-blue));
  }

  &:active {
    background: rgba(var(--color-dark));
  }

  &:disabled {
    background: rgba(var(--primary-color), .1);
    color: rgba(var(--text-color-light));
    cursor: not-allowed;
  }

  .button-text__loader {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-top: 4px solid rgba(var(--text-color-light));
    border-right: 4px solid rgba(var(--text-color-light));
    border-bottom: 4px solid rgba(var(--text-color-light));
    border-left: 4px solid rgba(156, 163, 184, .2);
    border-radius: 50%;
    animation: spin 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
