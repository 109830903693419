<template>
  <button
    class="invite"
    @click="$pop.up('invite')"
  >
    <div class="invite__text button-text">
      {{ $t('components.ui.buttons.invite-friends') }}
    </div>

    <div class="invite__icon button-icon">
      <svg
        width="16" height="16"
        style="min-width: 16px; min-height: 16px;"
        viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.69922 14.3002C1.69922 11.2074 4.20642 8.7002 7.29922 8.7002C8.04185 8.7002 8.75072 8.84475 9.39922 9.10726M11.4992 8.7002V11.5002M11.4992 11.5002V14.3002M11.4992 11.5002H8.69922M11.4992 11.5002H14.2992M10.0992 4.1502C10.0992 5.50329 9.00232 6.6002 7.64922 6.6002C6.29612 6.6002 5.19922 5.50329 5.19922 4.1502C5.19922 2.7971 6.29612 1.7002 7.64922 1.7002C9.00232 1.7002 10.0992 2.7971 10.0992 4.1502Z" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </button>
</template>

<style lang="scss" scoped>
button.invite {
  height: 36px;
  min-width: 210px;
  background: rgba(var(--primary-color), .2);
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: none;
  transition: .2s;
  cursor: pointer;

  &.nouppercase {
    .button-text {
      text-transform: none !important;
    }
  }

  &.text-left {
    .button-text {
      text-align: left !important;
    }
  }

  .button-text {
    width: 100%;
    padding: 0 10px;
    color: rgba(var(--color-dark));
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: SemiBold;
    line-height: 80%;
    transition: .2s;
  }

  .button-icon {
    height: 28px;
    width: 28px;
    min-width: 28px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--primary-color));
    border-radius: 50%;
    transition: .2s;
  }

  &:hover {
    background: rgba(var(--color-light-blue), 1);

    .button-text {
      color: rgba(255, 255, 255, 1);
    }

    .button-icon {
      background: white;

      svg path {
        stroke: rgba(var(--primary-color));
      }
    }
  }

  &:active {
    background: rgb(var(--color-dark));

    .button-text {
      color: rgba(255, 255, 255, 1);
    }

    .button-icon {
      background: white;

      svg path {
        stroke: rgba(var(--primary-color));
      }
    }
  }
}
</style>
