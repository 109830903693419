/* eslint-disable no-unused-vars */
import i18n from '../../config/i18n'

function encodeQueryData (value) {
  const query = []

  for (const i in value) {
    query.push(`${encodeURIComponent(i)}=${encodeURIComponent(value[i])}`)
  }

  return query.join('&')
}

export default {
  install: ({ config }, options) => {
    const { globalProperties } = config

    globalProperties.$redirect = {
      to: (path) => {
        window.location.href = path
      },
      auth: (value) => {
        const { $route } = globalProperties
        const currentLocale = i18n.global.locale || $route.params.locale || 'en'

        const { to, from, query } = value

        const parseTo = to
        const redirect = from.split('?')[0]
        const parseQuery = encodeQueryData({ ...query, redirect })

        window.location.href = `https://front-auth.demolix.org/${currentLocale}/${parseTo}?${parseQuery}`
      }
    }
  }
}
